/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { NumberBadge } from "gatsby-interface"
import { MdSubdirectoryArrowRight } from "react-icons/md"

import { headlineCss } from "../../../templates/starters/style-utils"

const showMoreButtonCss = t => ({
  border: 0,
  cursor: `pointer`,
  color: t.colors.gatsby,
  background: "transparent",
  appearance: "none",
  fontWeight: `semiBold`,
  padding: 0,
})

const StarterDependencies = ({
  allDeps,
  shownDeps,
  updateShowAllDeps,
  showMore,
  ...rest
}) => (
  <div
    sx={{
      display: `grid`,
      gridRowGap: 2,
    }}
    {...rest}
  >
    <h2
      sx={{ ...headlineCss, display: "flex", alignItems: "baseline", gap: 3 }}
    >
      Dependencies <NumberBadge>{allDeps.length}</NumberBadge>
    </h2>
    <div
      sx={t => ({
        display: `grid`,
        justifyItems: "left",
        [t.mediaQueries.desktop]: {
          gridTemplateColumns: `repeat(1, 1fr)`,
          gridGap: 2,
        },

        a: {
          textDecoration: "none",
        },
      })}
    >
      {shownDeps &&
        shownDeps.map(dep =>
          // gatsby-cypress is a helper plugin and not shown inside our plugins section
          // for that reason we are excluding it from our list of plugins
          /^gatsby-/.test(dep) && dep !== `gatsby-cypress` ? (
            <div key={dep}>
              <Link to={`/packages/${dep}`}>{dep}</Link>
            </div>
          ) : (
            <div key={dep}>
              <a href={`https://npmjs.com/package/${dep}`}>{`${dep} `}</a>
            </div>
          )
        )}
      {showMore && (
        <button sx={showMoreButtonCss} onClick={updateShowAllDeps}>
          <MdSubdirectoryArrowRight />
          {`Show ${allDeps.length - shownDeps.length} more`}
        </button>
      )}
    </div>
  </div>
)

export default StarterDependencies
