/** @jsx jsx */
import { jsx } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"

const SiteScreenshotDisplay = ({
  imageSharp,
  alt,
  boxShadow = true,
  ...rest
}) => (
  <div {...rest}>
    {imageSharp && (
      <GatsbyImage
        image={imageSharp}
        alt={alt ? alt : ``}
        sx={{
          boxShadow: boxShadow ? `dialog` : false,
        }}
      />
    )}
  </div>
)

export default SiteScreenshotDisplay
