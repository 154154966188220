/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import { graphql } from "gatsby"
import { contentPositionerCss } from "components/shared/styles"
import Layout from "../../layout"
import SiteHead from "../../head"
import config from "../../config"
import SiteScreenshotDisplay from "components/site-screenshot-display"
import { SkipNavTarget } from "components/shared/components/SkipNav"
import StarterHeader from "components/starters/details-sections/header"
import StarterMeta from "components/starters/details-sections/meta"
import StarterActions from "components/starters/details-sections/actions"
import StarterInstallation from "components/starters/details-sections/installation"
import StarterDependencies from "components/starters/details-sections/dependencies"
import StarterDescription from "components/starters/details-sections/description"
import StarterFeatures from "components/starters/details-sections/features"
import StarterTags from "components/starters/details-sections/tags"

const NUM_DEPS_TO_SHOW = 7

const hasDeps = allDeps => {
  return allDeps[0] !== "no data"
}

export function Head(props) {
  const title = props.data.wpStarter.githubMetaFields.stub
  const description = props.data.wpStarter.starterFields.description
  const image =
    config.url + props.data.wpStarter.starterFields.screenshot.resize.src

  return (
    <SiteHead
      {...props}
      title={title}
      description={description}
      image={image}
    />
  )
}

export default function StarterPage({ data: { wpStarter } }) {
  const [showAllDeps, setShowAllDeps] = useState(false)
  const {
    starterTags,
    starterFields: { description, features, repoUrl, url: demoUrl, screenshot },
    githubMetaFields,
  } = wpStarter

  // preprocessing of dependencies
  const { miscDependencies = [], gatsbyDependencies = [] } = githubMetaFields
  const allDeps = [
    ...gatsbyDependencies.map(([name]) => name),
    ...miscDependencies.map(([name]) => name),
  ]
  const shownDeps = showAllDeps ? allDeps : allDeps.slice(0, NUM_DEPS_TO_SHOW)
  const showMore = !showAllDeps && allDeps.length - shownDeps.length > 0

  const updateShowAllDeps = () => {
    setShowAllDeps(true)
  }

  return (
    <Layout>
      <div css={{ overflowX: "hidden" }}>
        <main css={theme => [contentPositionerCss({ theme })]}>
          <SkipNavTarget />
          <StarterHeader stub={githubMetaFields.stub} />
          <div
            css={theme => ({
              display: `grid`,
              gap: theme.space[7],
              gridTemplateColumns: `100%`,
              gridTemplateAreas: `
                'meta'
                'actions'
                'installation'
                'screenshot'
                'description'
                'features'
                'tags'
                'dependencies'
              `,
              [theme.mediaQueries.desktop]: {
                columnGap: theme.space[13],
                gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
                gridTemplateAreas: `
                 'meta'
                 'actions'
                 'installation'
                 'description'
                 'features'
                 'dependencies'
                 'tags'
                `,
              },
            })}
          >
            <StarterMeta
              starter={githubMetaFields}
              repoUrl={repoUrl}
              css={{ gridArea: "meta" }}
            />
            <StarterActions
              repoName={githubMetaFields.stub}
              imageSharp={screenshot}
              demo={demoUrl}
              repoUrl={repoUrl}
              css={theme => ({
                gridArea: "actions",

                [theme.mediaQueries.desktop]: {
                  alignSelf: "flex-end",
                },
              })}
            />
            <StarterInstallation
              repoName={githubMetaFields.stub}
              repoUrl={repoUrl}
              css={theme => ({
                gridArea: "installation",
                marginTop: `-${theme.space[5]}`,
              })}
            />
            <SiteScreenshotDisplay
              imageSharp={screenshot.gatsbyImage}
              alt={`Screenshot of ${githubMetaFields.stub}`}
              css={theme => ({
                width: "100%",
                gridArea: "screenshot",
                [theme.mediaQueries.desktop]: {
                  width: "52rem",
                  gridColumn: 2,
                  gridRow: `1 / -1`,
                },
              })}
            />
            <StarterDescription
              description={description}
              css={theme => ({
                gridArea: "description",
                margin: `${theme.space[7]} 0`,
              })}
            />
            <StarterFeatures
              features={features}
              css={{ gridArea: "features" }}
            />
            <StarterTags tags={starterTags.nodes} css={{ gridArea: "tags" }} />
            {hasDeps(allDeps) ? (
              <StarterDependencies
                allDeps={allDeps}
                shownDeps={shownDeps}
                showMore={showMore}
                updateShowAllDeps={updateShowAllDeps}
                css={{ gridArea: "dependencies" }}
              />
            ) : null}
          </div>
        </main>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query StarterDetailsQuery($id: String!) {
    wpStarter(id: { eq: $id }) {
      ...StarterDetails
    }
  }

  fragment StarterDetails on WpStarter {
    id
    title
    starterFields {
      description
      features
      repoUrl
      url
      screenshot {
        id
        gatsbyImage(layout: CONSTRAINED, width: 720, outputPixelDensities: [1])
        resize(width: 1500, height: 790, format: JPG, cropFocus: [TOP, LEFT]) {
          src
        }
      }
    }
    starterTags {
      nodes {
        id
        name
      }
    }
    githubMetaFields {
      slug
      stub
      name
      description
      stars
      lastUpdated
      owner
      githubFullName
      gatsbyMajorVersion
      allDependencies
      gatsbyDependencies
      miscDependencies
    }
  }
`
