/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { MdArrowBack } from "react-icons/md"

const Header = ({ stub }) => (
  <div
    className="starter-detail-header"
    sx={{
      py: 7,
      display: "grid",
      gap: 4,
    }}
  >
    <Link
      to={`/starters`}
      sx={{
        "&&": {
          fontSize: 1,
          borderBottom: 0,
          color: `link.color`,
          fontWeight: `body`,
          display: "flex",
          textDecoration: "none",
          alignItems: "center",
          justifySelf: "start",

          "&:hover": {
            color: `purple.60`,
          },
        },
      }}
    >
      <MdArrowBack sx={{ mr: 2 }} />
      All Starters
    </Link>
    <div>
      <h1 sx={{ m: 0, fontFamily: "body" }}>{stub}</h1>
    </div>
  </div>
)

export default Header
