export const headlineCss = {
  color: `text.primary`,
  fontWeight: `semiBold`,
  fontFamily: "body",
  fontSize: 1,
  mt: 0,
  mb: 2,
}

export const contentCss = {
  color: "text.secondary",
}
