/** @jsx jsx */
import { jsx } from "theme-ui"

import {
  headlineCss,
  contentCss,
} from "../../../templates/starters/style-utils"

const StarterTags = ({ tags, ...rest }) => (
  <div {...rest}>
    <h2 sx={headlineCss}>Tags</h2>
    <div sx={contentCss}>{tags.map(tag => tag.name).join(`, `)}</div>
  </div>
)

export default StarterTags
