/** @jsx jsx */
import { jsx } from "theme-ui"

import { contentCss } from "../../../templates/starters/style-utils"

const StarterFeatures = ({ features, ...rest }) => {
  return features ? (
    <div {...rest}>
      <ul
        sx={{
          ...contentCss,
          display: "grid",
          gap: 3,
          m: 0,
          listStyle: "none",
          ":marker": {
            color: "text.secondary",
          },
        }}
      >
        {features
          .split("\n")
          .filter(Boolean)
          .map((f, i) => (
            <li
              key={i}
              sx={theme => ({
                m: 0,
                color: theme.colors.grey[70],
                "&::before": {
                  pr: theme.space[3],
                  content: `"✓"`,
                },
              })}
            >
              {f}
            </li>
          ))}
      </ul>
    </div>
  ) : null
}

export default StarterFeatures
