import * as React from "react"
import { GoMarkGithub as GithubIcon } from "react-icons/go"

import { isHomepageStarter } from "../library/starter-card"

const linkStyles = theme => ({
  alignItems: `center`,
  display: `flex`,
  gap: theme.space[3],
  lineHeight: theme.lineHeights.solid,
})

const Source = ({ repoUrl, owner, name, ...rest }) =>
  repoUrl ? (
    <div
      css={theme => ({
        flexShrink: 0,
        display: "flex",
        gap: theme.space[2],
        alignItems: "center",
      })}
      {...rest}
    >
      <a href={repoUrl} css={linkStyles}>
        <GithubIcon />
        Source
      </a>
      {isHomepageStarter(name, owner) && (
        <React.Fragment>
          {" "}
          ·
          <a href={`${repoUrl}-ts`} css={linkStyles}>
            TypeScript source
          </a>
        </React.Fragment>
      )}
    </div>
  ) : null

export default Source
