/** @jsx jsx */
import { jsx } from "theme-ui"
import * as React from "react"
import { MdShare } from "react-icons/md"
import { FaLinkedin, FaFacebook, FaTwitter } from "react-icons/fa"
import { visuallyHiddenCss } from "./shared/styles/a11y"
import { IconAnchorButton } from "gatsby-interface"

const objectToParams = object =>
  `?` +
  Object.keys(object)
    .filter(key => !!object[key])
    .map(key => `${key}=${encodeURIComponent(object[key])}`)
    .join(`&`)

const shareMenuWrapperStyles = {
  position: `relative`,
  maxWidth: 36,
}

const ShareMenuItem = ({ href, icon, children }) => (
  <IconAnchorButton
    icon={icon}
    size="M"
    target="_blank"
    rel="noopener noreferrer"
    variant="GHOST"
    href={href}
    title={children}
  >
    {children}
  </IconAnchorButton>
)

const ShareMenu = props => {
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    document.addEventListener(`mousedown`, clickOutsideShareMenu)
    return () =>
      document.removeEventListener(`mousedown`, clickOutsideShareMenu)
  }, [open])

  const shareBtnRef = React.useRef(null)
  const shareMenuRef = React.useRef(null)

  const clickOutsideShareMenu = event => {
    const containedInBtn = shareBtnRef.current?.contains(event.target)
    const containedInMenu = shareMenuRef.current?.contains(event.target)

    if (open && !containedInBtn && !containedInMenu) {
      shareMenu()
    }
  }

  const shareMenu = () => {
    setOpen(!open)
  }

  const { url, title, className } = props

  return (
    <div sx={shareMenuWrapperStyles}>
      <IconAnchorButton
        variant={props.variant || "GHOST"}
        onClick={shareMenu}
        ref={shareBtnRef}
        className={className}
        icon={<MdShare />}
        size="M"
      >
        <span sx={visuallyHiddenCss}>Share</span>
      </IconAnchorButton>
      {open && (
        <div
          css={theme => ({
            background: theme.colors.white,
            boxShadow: theme.shadows.floating,
            left: `50%`,
            padding: theme.space[4],
            position: "absolute",
            top: 44,
            transform: `translateX(-50%)`,
            display: "grid",
            gap: theme.space[3],
            borderRadius: theme.radii[3],
            zIndex: theme.zIndices.modals,
          })}
          ref={shareMenuRef}
        >
          <ShareMenuItem
            href={`https://www.linkedin.com/shareArticle${objectToParams({
              mini: `true`,
              url: url,
              title: title,
            })}`}
            icon={<FaLinkedin />}
          >
            Share on LinkedIn
          </ShareMenuItem>
          <ShareMenuItem
            href={`https://www.facebook.com/sharer.php${objectToParams({
              u: url,
              t: title,
            })}`}
            icon={<FaFacebook />}
          >
            Share on Facebook
          </ShareMenuItem>
          <ShareMenuItem
            href={`https://twitter.com/share${objectToParams({
              url: url,
              text: title,
            })}`}
            icon={<FaTwitter />}
            title="Share on Twitter"
          >
            Share on Twitter
          </ShareMenuItem>
        </div>
      )}
    </div>
  )
}

export default ShareMenu
