/** @jsx jsx */
import { jsx } from "theme-ui"

const styles = {
  description: {
    color: `text.primary`,
    fontFamily: "body",
    fontSize: 2,
    fontWeight: `normal`,
    maxWidth: `40rem`,
  },
}

const StarterDescription = ({ description, ...rest }) => (
  <div css={{ display: "grid", gap: 4 }} {...rest}>
    <div sx={styles.description}>{description}</div>
  </div>
)

export default StarterDescription
