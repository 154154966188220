/** @jsx jsx */
import { jsx } from "theme-ui"
import Copy from "components/copy"

/*
Returns the name of the starter from the copied selection, e.g. 'npx gatsby new repoName repoUrl' returns 'repoName'

Returns undefined if selection is undefined or the copied selection isn't the full command
*/
const getStarterFromSelection = selection => {
  if (selection) {
    return selection.toString().split(" ")[3]
  }
}

const StarterInstallation = ({ repoName, repoUrl, ...rest }) => {
  const content = `npx gatsby new ${repoName || `my-gatsby-project`} ${repoUrl}`

  return (
    <div
      sx={{
        bg: `grey.5`,
        borderRadius: 3,
        position: "relative",
        whiteSpace: "nowrap",
        minWidth: 0,
      }}
      {...rest}
    >
      <div sx={{ px: 4, pt: 3, fontSize: 1, color: `grey.60` }}>
        Install with gatsby-cli
      </div>
      <pre
        sx={{
          background: "transparent",
          overflowX: `auto`,
          m: 0,
          px: 4,
          py: 3,
        }}
      >
        <code
          sx={{
            fontWeight: "semibold",
            fontSize: 2,
          }}
        >
          {content}
        </code>
      </pre>
      <Copy
        fileName="Install command"
        content={content}
        sx={{ borderRadius: 1, position: `absolute`, right: 4, top: 2 }}
        data-ld="btn-copy-starter-cli-cmd"
      />
    </div>
  )
}

export default StarterInstallation
