/** @jsx jsx */
import { jsx } from "theme-ui"
import { MdStar } from "react-icons/md"
import { Badge } from "gatsby-interface"

import StarterSource from "./source"

const Meta = ({ starter, repoUrl, ...rest }) => (
  <div
    sx={{
      color: "text.secondary",
      display: `flex`,
      flexShrink: 0,
      fontSize: 1,
      gap: 6,
      rowGap: 4,
      flexWrap: `wrap`,
      alignItems: "center",
    }}
    {...rest}
  >
    {/* if !starter.owner we have faulty GitHub data :doge: */}
    {starter.owner && (
      <div css={theme => ({ display: "flex", gap: theme.space[3] })}>
        {starter.owner === "gatsbyjs" && (
          <Badge variant="STATUS" tone="BRAND" textVariant="DEFAULT">
            Official
          </Badge>
        )}
      </div>
    )}

    {starter.owner && (
      <div>
        By{` `}
        <a href={`https://github.com/${starter.owner}`}>{starter.owner}</a>
      </div>
    )}

    <StarterSource
      repoUrl={repoUrl}
      owner={starter.owner}
      name={starter.name}
    />

    {starter.stars > 0 && (
      <div
        sx={{
          alignItems: `center`,
          color: `text.secondary`,
          display: `inline-flex`,
          gap: 2,
        }}
        ariaLabel={`${starter.stars} stars`}
      >
        <MdStar /> {starter.stars}
      </div>
    )}

    {starter.lastUpdated && <div>Updated {showDate(starter.lastUpdated)}</div>}
  </div>
)

function showDate(dt) {
  const date = new Date(dt)
  return date.toLocaleDateString(`en-US`, {
    month: `short`,
    day: `numeric`,
    year: `numeric`,
  })
}

export default Meta
